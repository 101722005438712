import axios from "axios";
import {WEB_CONFIG} from "../config/Static";
const apiEndpoint =WEB_CONFIG.server+"/api/general/";
axios.defaults.withCredentials = true;
axios.defaults.crossDomain = true;
export const getLeftleagues = async (lang) => await axios.get(`${WEB_CONFIG.server}/getmatches/left_${lang}`);
export const changePass = async (userdata) => await axios.post(`${WEB_CONFIG.server}/api/user/changepassword`,userdata);
export const getLeagues = async (id,lang) => await axios.get(`${WEB_CONFIG.server}/getmatches/${id}_${lang}`);
export const fetchData = async (datatype) => await axios.get(`${apiEndpoint}getdata/${datatype}`);
export const fetchRows = async (datatype,query) => await axios.post(`${apiEndpoint}getrows/${datatype}`,query);
export const fetchOrders = async (datatype,query) => await axios.post(`${apiEndpoint}getorders/${datatype}`,query);
export const deleteorderData = async (datatype,query) => await axios.post(`${apiEndpoint}deleteorder/${datatype}`,query);
export const fetchOrdersByField = async (market,side) => await axios.get(`${apiEndpoint}field/?side=${side}&symbol=${market}`);
export const fetchSingleOrder = async (id) =>
    await axios.get(`${apiEndpoint}${id}`);

export const addnewData = async (options,data) => await axios.post(`${apiEndpoint}addnewdata/${options}`, data);

export const updateData = async (options, post) =>
    await axios.post(`${apiEndpoint}updatedata/${options}`,post);

//yeni update
export const updateDatas = async (table,upquery,resultcol) =>
    await axios.post(`${apiEndpoint}updatedatas/${table}`,upquery);
export const deleteGeneral = async (table,upquery) =>
    await axios.post(`${apiEndpoint}deletegeneral/${table}`,upquery);
export const botSorgu = async (table,upquery) =>
    await axios.post(`${apiEndpoint}botsorgu/${table}`,upquery);
export const deleteData = async (secenek,id) =>
    await axios.get(`${apiEndpoint}deletedata/${secenek}/${id}`);
export const updateMultiple = async (secenek,post) =>
    await axios.post(`${apiEndpoint}${secenek}`,post);
