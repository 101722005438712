import React, {useEffect} from 'react';
import Header from '../components/site/Header';
import Topnav from '../components/site/Topnav';
import Leftnav from "../components/user/Leftnav";
import Usercontainer from "../components/user/Usercontainer";
import jwtDecode from "jwt-decode";
import axios from "axios";
import {WEB_CONFIG} from "../config/Static";
import {useSelector} from "react-redux";
export default function Sportbets (currentAppLocale) {
    const lang = currentAppLocale.messages;
    const [date,setDate] = React.useState("")
    const [date2,setDate2] = React.useState("")
    const [cp,setCp] = React.useState([])
    const allData = useSelector((state) => state.general);
    const Transactions = async () => {
        if (localStorage.getItem("gsdhhrxchbhlkpkakd")) {
            const jwt_Token_decoded = await jwtDecode(localStorage.getItem("gsdhhrxchbhlkpkakd"));
       await     axios.post(
                WEB_CONFIG.server + '/api/sport/transactions',
                jwt_Token_decoded,
                {headers: {'Content-Type': 'application/json'}}
            )
                .then(response => {

                    setCp(response.data);
                })
                .catch(error => {
                    console.log(error.data)
                });


        }
    }
    useEffect(() => {
        Transactions();
    },[])

    const setSearch = () => {
        let sdata= {
            "user":allData.userdata,
            "date":date,
            "date2":date2

        }

        axios.post(
            WEB_CONFIG.server+'/api/sport/transactionsearch',
            sdata,
            { headers: { 'Content-Type': 'application/json' }}
        )
            .then(response =>{

                setCp(response.data);
            })
            .catch(error => {
                console.log(error.data)
            });
    }
    return (
        <div className="main">
            <Header lang={currentAppLocale} />
            <Topnav lang={currentAppLocale} />
            <div className="main">
                <div className={"row"}>
                    <div className={"col-md-2"}>
                        <Leftnav lang={currentAppLocale} />
                    </div>
                    <div className={"col-md-10"}>
                        <div className={"section_title "}>{lang['account']}</div>
                        <div className={"section_title acc_filter"}>
                            <div className={"row"}>
                                <div className={"col-md-3"}>
                                    <label>{lang['date']}:</label>
                                    <div className={"row"}>
                                        <input value={date || ""} onChange={(e)=>{setDate(e.target.value)}}  type={"date"} className={"text_input"}/>
                                        /
                                        <input value={date2 || ""} onChange={(e)=>{setDate2(e.target.value)}} type={"date"} className={"text_input"}/>
                                    </div>

                                </div>
                                <div className={"col-md-3"}>
                                    <label>{lang['user']}:</label>
                                    <select className={"filter_select"}>
                                        <option value={"0"}>{lang['main_dealer']}</option>
                                    </select>
                                </div>


                                <div className={"col-md-2"}>
                                    <button onClick={()=>{setSearch()}} className={"btn filter_btn"}>{lang['filter']}</button>
                                </div>
                            </div>
                        </div>
                        <div className={"bet_table"}>
                            <table className={"bet_table"}>
                                <thead>
                                <tr>{lang['date']}</tr>
                                <tr>{lang['withdraw']}</tr>
                                <tr>{lang['deposit']}</tr>
                                <tr>{lang['balance']}</tr>
                                <tr>{lang['desc']}</tr>
                                <tr>{lang['status']}</tr>
                                </thead>
                                <tbody>

                                {cp &&
                                cp.map((t,index) => {
                                    return (
                                <tr key={index}>
                                    <td>{t.date}</td>
                                    <td>{t.total}</td>
                                    <td>0</td>
                                    <td>950</td>
                                    <td>{lang['deposit']}</td>
                                    <td>{t.status}</td>
                                </tr>
                                   );
                                })
                                }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

