import React, {useEffect} from 'react';
import Header from '../components/site/Header';
import Topnav from '../components/site/Topnav';
import Leftnav from "../components/user/Leftnav";
import {useSelector} from "react-redux";
import axios from "axios";
import {WEB_CONFIG} from "../config/Static";
import jwtDecode from "jwt-decode";

export default function Members (currentAppLocale) {
    const lang = currentAppLocale.messages;
    const allData = useSelector((state) => state.general);
    const [panel,setPanel]= React.useState(false)
    const [editpanel,setEditpanel]= React.useState(false)
    const [edituser,setEdituser]= React.useState([])
    const [cp,setCp]= React.useState(false)
    const [warn,setWarn]= React.useState({
        status: "",
        message:""
    })
    const [userform,setUserForm]= React.useState({})
    const [usereditform,setUsereditForm]= React.useState({})
    const addUser = () => {
        let userdata = userform;
        userdata.ref = allData.userdata.user_id;
        axios.post(
            WEB_CONFIG.server + '/api/user/create',
            userdata,
            {headers: {'Content-Type': 'application/json'}}
        )
            .then(response => {

                setWarn(response.data);
                setTimeout(function(){
                    setWarn({})
                    setUserForm({})
                },3000)
            })
            .catch(error => {
                console.log(error.data)
            });
    }
    const setForm = (input,name) => {

        let key = name.toString()
        let val = input
        let obj  = {}
        obj[key] = val
        setUserForm({
            ...userform,
            [key]:input
        })

    }
    const setForm2 = (input,name) => {

        let key = name.toString()
        let val = input
        let obj  = {}
        obj[key] = val
        setUsereditForm({
            ...usereditform,
            [key]:input
        })

    }

    const myUsers = async () => {
        if (localStorage.getItem("gsdhhrxchbhlkpkakd")) {
            const jwt_Token_decoded = await jwtDecode(localStorage.getItem("gsdhhrxchbhlkpkakd"));
            axios.post(
                WEB_CONFIG.server + '/api/user/myusers',
                jwt_Token_decoded,
                {headers: {'Content-Type': 'application/json'}}
            )
                .then(response => {

                    setCp(response.data);
                })
                .catch(error => {
                    console.log(error.data)
                });


        }

    }
    useEffect(() => {
        myUsers();
    },[])
    const Edituser = (user) => {
        setEditpanel(true);
        setPanel(false)
        delete user.password;
        setUsereditForm(user);
    }
    const openAdd = () => {
        setEditpanel(false);
        setPanel(true)

    }
    const userEdit = () => {
        let userdata = usereditform;

        axios.post(
            WEB_CONFIG.server + '/api/user/edits',
            userdata,
            {headers: {'Content-Type': 'application/json'}}
        )
            .then(response => {

                setWarn(response.data);
                myUsers();
                setTimeout(function(){
                    setWarn({})
                    setUserForm({})
                },3000)
            })
            .catch(error => {
                console.log(error.data)
            });
    }
    return (
        <div className="main">
            <Header lang={currentAppLocale} />
            <Topnav lang={currentAppLocale}/>
            <div className="main">
                <div className={"row"}>
                    <div className={"col-md-2"}>
                        <Leftnav lang={currentAppLocale} />
                    </div>
                    <div className={"col-md-10"}>
                        <div className={"section_title"}>{lang['members']}</div>

                        <div className={"row"}>
                            {warn.status && (
                                <div className={"warning"}>
                                    <h3>{lang[warn.status]}</h3>
                                    <p>{lang[warn.message]}</p>
                                </div>
                            )}
                            <div className={"col-md-6"}>
                                <div className={"section_title"}>{lang['user_list']}</div>
                                <div className={"section_title acc_filter mt-5"}>
                                    <div className={"row"}>
                                        <div className={"col-md-3"}>
                                            <label>{lang['search_user']}:</label>
                                            <input  type={"text"} className={"text_input"}/>

                                        </div>
                                        <div className={"col-md-3"}>
                                        </div>
                                        <div className={"col-md-4"}>
                                            <button onClick={()=>openAdd()} className={"btn add_btn"}>+ {lang['add_user']}</button>
                                        </div>
                                    </div>

                                    <div className={"bet_table"}>
                                        <table className={"bet_table2"}>
                                            <thead>
                                            <tr>{lang['code']}</tr>
                                            <tr>{lang['username']}</tr>
                                            <tr>{lang['name']}</tr>
                                            <tr>{lang['current_limit']}</tr>
                                            <tr>{lang['edit']}</tr>

                                            </thead>
                                            <tbody>

                                            {cp &&
                                            cp.map((u,index) => {
                                                return (
                                            <tr>
                                                <td>{u.user_id}</td>
                                                <td>{u.userName}</td>
                                                <td>{u.firstName} {u.lastName}</td>
                                                <td>{u.balance}</td>
                                                <td><img onClick={()=>Edituser(u)} src={"/images/icons/edit.png"}  alt={"Edit"} /></td>

                                            </tr>
                                                );
                                            })
                                            }
                                            </tbody>
                                        </table>
                                    </div>



                                </div>
                            </div>
                            <div className={"col-md-6"}>
                                <div className={"section_title"}>{lang['user_details']}</div>
                                {panel && (
                                    <div className={"add_usr"}>


                                        <input value={userform.firstName || ""} onChange={(e) =>setForm(e.target.value,"firstName")} placeholder={lang['name']} type={"text"} />
                                        <input value={userform.lastName || ""} onChange={(e) =>setForm(e.target.value,"lastName")} placeholder={lang['surname']} type={"text"} />
                                        <input value={userform.email || ""} onChange={(e) =>setForm(e.target.value,"email")} placeholder={lang['username']} type={"text"} />
                                        <input value={userform.password || ""} onChange={(e) =>setForm(e.target.value,"password")} placeholder={lang['password']} type={"password"} />
                                        <input value={userform.limit || ""}  onChange={(e) =>setForm(e.target.value,"limit")} placeholder={lang['account_limit']+"(Max:"+allData.userbalance+")"} type={"text"} />
                                        <input value={userform.phone || ""} onChange={(e) =>setForm(e.target.value,"phone")} placeholder={lang['phone']} type={"text"} />
                                        <button  onClick={()=>{addUser()}} className={"btn add_user_btn"}>{lang['add_user']}</button>
                                    </div>
                                )}




                                {editpanel && (
                                    <div className={"add_usr"}>
                                        <label>{lang['username']}</label>
                                        <input value={usereditform.userName || edituser.userName} onChange={(e) =>setForm2(e.target.value,"userName")} placeholder={lang['name']} type={"text"} />
                                        <label>{lang['password']}</label>
                                        <input value={usereditform.password || ""} onChange={(e) =>setForm2(e.target.value,"password")} placeholder={lang['password']} type={"text"} />

                                        <label>{lang['name']}</label>
                                        <input value={usereditform.firstName || edituser.firstName} onChange={(e) =>setForm2(e.target.value,"firstName")} placeholder={lang['name']} type={"text"} />
                                        <label>{lang['surname']}</label>
                                        <input value={usereditform.lastName || edituser.lastName} onChange={(e) =>setForm2(e.target.value,"lastName")} placeholder={lang['surname']} type={"text"} />
                                        <label>{lang['balance']}</label>
                                        <input value={usereditform.limit || edituser.limit}  onChange={(e) =>setForm2(e.target.value,"limit")} placeholder={lang['account_limit']+" (Max:"+allData.userbalance+")"} type={"text"} />
                                        <label>{lang['phone']}</label>
                                        <input value={usereditform.phone || edituser.phone} onChange={(e) =>setForm2(e.target.value,"phone")} placeholder={lang['phone']} type={"text"} />
                                        <button  onClick={()=>{userEdit()}} className={"btn add_user_btn"}>{lang['edit_user']}</button>
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );

}

