import React, {useEffect} from 'react';
import Header from '../components/site/Header';
import Topnav from '../components/site/Topnav';
import Leftnav from "../components/user/Leftnav";
import axios from "axios";
import {WEB_CONFIG} from "../config/Static";
import {userBalance} from "../actions/general";
import {useDispatch, useSelector} from "react-redux";
import jwtDecode from "jwt-decode";

export default function Sportbets (currentAppLocale) {
    const lang = currentAppLocale.messages;
    const allData = useSelector((state) => state.general);
    const [cp,setCp] = React.useState([])
    const [date,setDate] = React.useState("")
    const [date2,setDate2] = React.useState("")
    const [user,setUser] = React.useState("")
    const [ticket,setTicket] = React.useState("")
    const Coupons = async () => {
        if (localStorage.getItem("gsdhhrxchbhlkpkakd")) {
            const jwt_Token_decoded = await jwtDecode(localStorage.getItem("gsdhhrxchbhlkpkakd"));
          axios.post(
                WEB_CONFIG.server + '/api/sport/coupons',
                jwt_Token_decoded,
                {headers: {'Content-Type': 'application/json'}}
            )
                .then(response => {

                    setCp(response.data);
                })
                .catch(error => {
                    console.log(error.data)
                });


        }

    }
  useEffect(() => {
          Coupons();
  },[])
    const openCoupon = (id) => {
        window.location.href = "/coupon_detail/"+id;
    }
    const setSearch = () => {
     let sdata= {
         "user":allData.userdata,
         "date":date,
         "date2":date2,
         "ticket":ticket
     }

        axios.post(
            WEB_CONFIG.server+'/api/sport/couponsearch',
            sdata,
            { headers: { 'Content-Type': 'application/json' }}
        )
            .then(response =>{
                setCp(response.data);
            })
            .catch(error => {
                console.log(error.data)
            });
    }
    const delSearch = () => {
        let sdata= {
            "user":allData.userdata,
            "date":"",
            "date2":"",
            "ticket":""
        }
        axios.post(
            WEB_CONFIG.server+'/api/sport/couponsearch',
            sdata,
            { headers: { 'Content-Type': 'application/json' }}
        )
            .then(response =>{
                setDate("");
                setDate2("");
                setTicket("")
                setCp(response.data);
            })
            .catch(error => {
                console.log(error.data)
            });
    }
    return (
        <div className="main">
            <Header lang={currentAppLocale} />
            <Topnav lang={currentAppLocale} />
            <div className="main">
                <div className={"row"}>
                    <div className={"col-md-2"}>
                        <Leftnav lang={currentAppLocale}/>
                    </div>
                    <div className={"col-md-10"}>
                        <div className={"section_title "}>{lang["sportbets"]}</div>
                        <div className={"section_title bet_filter"}>
                            <div className={"row"}>
                            <div className={"col-md-3"}>
                                <label>{lang["date"]}:</label>
                                <div className={"row"}>
                                    <input value={date || ""} onChange={(e)=>{setDate(e.target.value)}}  type={"date"} className={"text_input"}/>
                                    /
                                    <input value={date2 || ""} onChange={(e)=>{setDate2(e.target.value)}} type={"date"} className={"text_input"}/>
                                </div>

                            </div>
                            <div className={"col-md-2"}>
                                <label>{lang["user"]}:</label>
                                <select className={"filter_select"}>
                                    <option value={"0"}>{lang["main_dealer"]}</option>
                                </select>
                            </div>
                            <div className={"col-md-2"}>
                                <label>{lang["filter"]}:</label>
                                <select className={"filter_select"}>
                                    <option value={"0"}>{lang["all_bets"]}</option>
                                </select>
                            </div>
                            <div className={"col-md-2"}>
                                <label>{lang["ticket_no"]}:</label>
                                <input value={ticket || ""} onChange={(e)=>{setTicket(e.target.value)}} type={"text"} className={"text_input"}/>
                            </div>
                            <div className={"col-md-3"}>
                                <button onClick={()=>{setSearch()}} className={"btn filter_btn"}>{lang["filter"]}</button>
                                <button onClick={()=>{delSearch()}} className={"btn filter_btn filter_clear"}>{lang["clear"]}</button>
                            </div>
                        </div>
                        </div>
                        <div className={"bet_table"}>
                            <table className={"bet_table"}>

                                <thead>
                                <tr>{lang["date"]}</tr>
                                <tr>{lang["status"]}</tr>
                                <tr>{lang["bet_type"]}</tr>
                                <tr>{lang["bet_amount"]}</tr>
                                <tr>{lang["max_gain"]}</tr>
                                <tr>{lang["ticket_no"]}</tr>
                                </thead>
                                <tbody>


                                {cp &&
                                    cp.map((coupon,index) => {
                                        let bg = 'whitebg';
                                        if(coupon.winstatus==="WON") { bg="greenbg"; } else if(coupon.winstatus==="LOST") { bg="redbg"; } else { bg="whitebg"; }
                                        return (
                                            <tr className={bg} onClick={() => {openCoupon(coupon.coupon_id)}} key={index}>
                                                <td>{coupon.date}</td>
                                                <td>{lang[coupon.status]}</td>
                                                <td>{lang[coupon.winstatus]}</td>
                                                <td>{coupon.price}</td>
                                                <td>{coupon.total}</td>
                                                <td>{coupon.coupon_id}</td>
                                            </tr>
                                        );
                                    })
                                }



                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
