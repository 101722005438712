import React from "react";
import {useParams, useLocation, Link} from "react-router-dom";

const Topnav = (props) => {
    let par = useLocation().pathname
    const lang = props.lang.messages;
    return (
        <div className={"top_nav"}>
            <div className={"container"}>
            <ul className={"top_menu"}>
                <li className={par==='/'?"active":""}><Link to={"/"}>{lang["sport_bets"]}</Link></li>
                <li className={par==='/live'?"active":""}><Link to={"/live"}>{lang["live_matches"]}</Link></li>
                <li><a href={"#"}>{lang["bingo"]}</a></li>
            </ul>
            </div>

        </div>

    );
}
export  default Topnav;
