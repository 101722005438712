import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getLeagues} from "../../actions/general";
import {useCookies} from "react-cookie";
const Leftnav = (props) => {
    const [cookies,setCookie] = useCookies();
   const [open,setOpen] = useState(0)
    const allData = useSelector((state) => state.general);
    const [subopen,setSubopen] = useState("0")
   const dispatch = useDispatch();
   const  openLeague = (id,e,title,image) => {
       e.preventDefault();
       if (cookies.lang) {
           dispatch(getLeagues(id, "league", title, image,cookies.lang));
       }
       else {
           dispatch(getLeagues(id, "league", title, image,"en"));
       }

   }
    const openItems = (Id) => {
        if(open===Id) { setOpen(0); } else { setOpen(Id) }
    }
    const opensub = (id) => {

        setSubopen(id);
    }
     return (
        <div className={"left_nav"}>

    <div className={"widget_title_center"}> <span>{props.lang["favorite_leagues"]}</span></div>
            <ul className={"left_leagues"}>
    <li ><a onClick={e => {openLeague(2951,e,"Super Lig",'TUR')}}><img src={"/images/flags/tr.gif"} /> <span className={"widget_l"}>{props.lang["turkey_super_league"]}</span> </a></li>
    <li ><a onClick={e => {openLeague(2936,e,"Premier League",'ENG')}}><img src={"/images/flags/uk.gif"} /> <span className={"widget_l"}>{props.lang["premiere_league"]}</span></a></li>
    <li ><a onClick={e => {openLeague(2941,e,"LaLiga",'ESP')}}><img src={"/images/flags/es.gif"} /> <span className={"widget_l"}>{props.lang["la_liga"]}</span></a></li>
    <li ><a onClick={e => {openLeague(2950,e,"Bundesliga",'DEU')}}><img src={"/images/flags/de.gif"} /> <span className={"widget_l"}>{props.lang["bundesliga"]}</span></a></li>
    <li ><a onClick={e => {openLeague(2942,e,"Serie A",'ITA')}}><img src={"/images/flags/it.gif"} /> <span className={"widget_l"}>{props.lang["serie_a"]}</span></a></li>
    <li ><a onClick={e => {openLeague(2943,e,"Ligue 1",'FRA')}}><img src={"/images/flags/fr.gif"} /> <span className={"widget_l"}>{props.lang["ligue_1"]}</span></a></li>
    <li ><a onClick={e => {openLeague(3065,e,"Eredivisie",'NLD')}}><img src={"/images/flags/ne.gif"} /> <span className={"widget_l"}>{props.lang["eerste_divisie"]}</span></a></li>
    <li ><a onClick={e => {openLeague(2965,e,"First Division A",'BEL')}}><img src={"/images/flags/be.gif"} /> <span className={"widget_l"}>{props.lang["pro_league"]}</span></a></li>
            </ul>

            <div className={"widget_title_center"}> <span>{props.lang["sport_bets"]}</span></div>
            <div onClick={()=>{openItems("football")}} className={"widget_title"}><span><img src={"/images/icons/football.png"} />
                <span className={"widget_l"}>{props.lang["football"]}</span>
                <span className={"widget_r"}>120</span></span></div>
            {open==='football' && (
            <ul className={"left_leagues"}>
                {allData.leftleagues.length>0 && (
                    allData.leftleagues.map((league,index) => (

                        <li className={"firstnav"} key={index}><span >
                            {league.ISO?(<img src={"/images/flags/"+league.ISO+".png"} />):(<img src={"/images/flags/WRL.png"} />)}
                            <span>{props.lang[league.Name]?props.lang[league.Name]:league.Name}</span>
                            <span className={"right_align"}>
                                <input onChange={()=>opensub(league.Id)} type={"checkbox"}/></span>
                        </span>
                            {subopen===league.Id && (
                                <>
                                    {league.Items.length>0 && (

                                        <ul className={"subnav "+league.Id+" "+subopen}>
                                            {league.Items.map((item,indexi) =>(
                                                <li  key={indexi}><span>
                                           {league.ISO?(<img src={"/images/flags/"+league.ISO+".png"} />):(<img src={"/images/flags/WRL.png"} />)}
                                                    <span>{item.Name}</span>
                                           <span onChange={e => {openLeague(item.Id,e,item.Name,league.ISO?league.ISO:'WRL')}} className={"right_align"}>
                                               <input type={"checkbox"}/></span>
                                       </span></li>
                                            ))}
                                        </ul>
                                    )}
                                </>
                            )}


                        </li>
                    ))
                )}


            </ul>)}

            <div onClick={()=>{openItems("basketball")}} className={"widget_title"}><a href={"#"}><img src={"/images/icons/basketball.png"} /> <span className={"widget_l"}>{props.lang["basketball"]}</span><span className={"widget_r"}>25</span></a></div>
            <div onClick={()=>{openItems("volleyball")}} className={"widget_title"}><a href={"#"}><img src={"/images/icons/volleyball.png"} /> <span className={"widget_l"}>{props.lang["volleyball"]}</span><span className={"widget_r"}>18</span></a></div>
            <div onClick={()=>{openItems("tennis")}} className={"widget_title"}><a href={"#"}><img src={"/images/icons/tennis.png"} /> <span className={"widget_l"}>{props.lang["tennis"]}</span><span className={"widget_r"}>8</span></a></div>
            <div onClick={()=>{openItems("other_sports")}} className={"widget_title"}><a href={"#"}><img src={"/images/icons/sports.png"} /> <span className={"widget_l"}>{props.lang["other_sports"]}</span><span className={"widget_r"}>0</span></a></div>

        </div>
    );
}
export  default Leftnav;
