import React from 'react';
import {useSelector} from "react-redux";



export default function Usercontainer (props) {
    const allData = useSelector((state) => state.general);
    const lang = props.lang.messages;
    return (
    <div className={"user_container"}>
        <div className={"row"}>
            <div className={"col-md-1 user_pp"}>
                <img className={"profile_img"} src={"/images/users/pp.png"}/>
            </div>
            <div className={"col-md-11 detail_box"}>
                <div className={"row user_row"}>
                    <div className={"user_key"}>{lang['code']}:</div>
                    <div className={"user_value"}>{allData.userdata.user_id}</div>
                </div>
                <div className={"row user_row"}>
                    <div className={"user_key"}>{lang['name']}:</div>
                    <div className={"user_value"}>{allData.userdata.name}
                    </div>
                </div>
                <div className={"row user_row"}>
                    <div className={"user_key"}>{lang['surname']}:</div>
                    <div className={"user_value"}>{allData.userdata.lastname}</div>
                </div>


            </div>

        </div>
        <div className={"row user_row"}>
            <div className={"col-md-6"}>
                <div className={"row"}>
                    <div className={"user_key"}>{lang['current_limit']}:</div>
                    <div className={"user_value"}>{allData.userbalance} DNR</div>
                </div>
            </div>
            <div className={"col-md-6"}>
                <div className={"row"}>
                    <div className={"user_key"}>{lang['single_llmit']}:</div>
                    <div className={"user_value"}>{allData.userbalance} DNR</div>
                </div>
            </div>
        </div>
        <div className={"row user_row"}>
            <div className={"col-md-6"}>
                <div className={"row"}>
                    <div className={"user_key"}>{lang['commission_type']}:</div>
                    <div className={"user_value"}>%0</div>
                </div>
            </div>
            <div className={"col-md-6"}>
                <div className={"row"}>
                    <div className={"user_key"}>{lang['cancel_limit']}:</div>
                    <div className={"user_value"}>0 </div>
                </div>
            </div>
        </div>
        <div className={"row user_row"}>
            <div className={"col-md-6"}>
                <div className={"row"}>
                    <div className={"user_key"}>{lang['daily_limit']}:</div>
                    <div className={"user_value"}>31</div>
                </div>
            </div>
            <div className={"col-md-6"}>
                <div className={"row"}>
                    <div className={"user_key"}>{lang['member_limit']}:</div>
                    <div className={"user_value"}>99</div>
                </div>
            </div>
        </div>
        <div className={"row user_row"}>
            <div className={"user_key"}>{lang['phone']}:</div>
            <div className={"user_value"}>{allData.userdata.phone}</div>
        </div>
        <div className={"row user_row"}>
            <div className={"user_key"}>{lang['email']}:</div>
            <div className={"user_value"}>{allData.userdata.email}</div>
        </div>
        <div className={"row user_row"}>
            <div className={"user_key"}>{lang['address']}:</div>
            <div className={"user_value"}>-</div>
        </div>
    </div>

    );

}

