import React, {useEffect} from "react";
import axios from "axios";
import {Link, useLocation, useParams} from 'react-router-dom';
import { useCookies } from 'react-cookie';
import jwtDecode from "jwt-decode";
import {userDetails, userBalance, changeLanguage, getLeagues} from "../../actions/general";
import {setAuthorizationToken} from "../../helpers/setAuthorizationToken";
import {WEB_CONFIG} from "../../config/Static";
import {useDispatch, useSelector} from "react-redux";
import teams_ar from "../../lang/locales/team_ar.json";
import teams_en from "../../lang/locales/team_en.json";
const Header = (props) => {
    const location = useLocation().pathname;
    const lang = props.lang.messages;
    const dispatch = useDispatch();
    const [cookies,setCookie] = useCookies();
    const [balance, setBalance] = React.useState(0);
    const [account, setAccount] = React.useState([]);
    const [message, setMessage] = React.useState("");
    const [status, setStatus] = React.useState("none");
    const [userdata, setUserdata] = React.useState({
        "email":"",
        "password":""
    });
    const allData = useSelector((state) => state.general);

    const onSubmit = () => {
        axios.post(
            WEB_CONFIG.server+'/api/user/login',
            userdata,
            { headers: { 'Content-Type': 'application/json' }}
            )
            .then(response =>{
                setMessage(response.data.message);

                if(response.data.success === true) {
                    setStatus('alert alert-success block');
                    let tok = jwtDecode(response.data.token);
                    localStorage.setItem("gsdhhrxchbhlkpkakd", response.data.token);
                    setAccount(response.data);
                    setAuthorizationToken(response.data.token);
                    const enc = Buffer.from(window.navigator.appVersion, 'utf8').toString('base64');
                    if(cookies) {}
                    setCookie(tok.cjwtpto, enc, {
                        path : '/',
                        sameSite:true
                    });

                    window.location.reload();
                }
                else {
                    setStatus('alert alert-danger block');
                }

            })
            .catch(error => {
                // console.log(error.data)
            });
    };
    const Logout = () => {
        localStorage.clear();
        window.location.reload();
    }
    function setForm(t,name) {
       //   console.log("t"); console.log(userdata);
          //console.log("name"); console.log(name);
        let key = name.toString()
        let val = t
        let obj  = {}
        obj[key] = val
        setUserdata({
            ...userdata,
            [key]:t.target.value
        })

        // console.log("183 account");  console.log(account);
        //console.log("183 user");  console.log(user);
    }
    const  Auth = async () => {
        if (cookies.lang) {
            if(cookies.lang==="ar") {
                dispatch(changeLanguage(teams_ar,cookies.lang))
            }
            else {
                dispatch(changeLanguage(teams_en,cookies.lang))
            }
        }
        else {

            setCookie("lang", "en");
            dispatch(changeLanguage(teams_ar,"en"))
        }
        if (localStorage.getItem("gsdhhrxchbhlkpkakd")) {
            try {
                const jwt_Token_decoded = await jwtDecode(localStorage.getItem("gsdhhrxchbhlkpkakd"));
                if (jwt_Token_decoded.exp * 1000 < Date.now()) {
                    localStorage.clear();
                    return false;

                } else {
                    axios.post(
                        WEB_CONFIG.server+'/api/sport/balance',
                        jwt_Token_decoded,
                        { headers: { 'Content-Type': 'application/json' }}
                        )
                        .then(response =>{
                            setBalance(response.data.balance);
                            dispatch(userBalance(response.data.balance))
                           })
                 .catch(error => {
                     console.log(error.data)
                });
                    setAccount(jwt_Token_decoded);
                    dispatch(userDetails(jwt_Token_decoded))
                    return true;
                }
            } catch (err) {
                localStorage.clear();
                 //console.log(err);
                return false;
            }

        } else {
            localStorage.clear();
            //console.log("token yok");
            return false;
        }

    }
    useEffect(() => {
        Auth();
    }, [balance]);

    const changelang = (langid) => {
         setCookie("lang",langid)
       if(langid==="ar") {
           dispatch(changeLanguage(teams_ar,langid))
       }
       else {
           dispatch(changeLanguage(teams_en,langid))
       }

        if(location==="/live") {
            dispatch(getLeagues("live", "general", lang["latest_matches"], "WRL",langid));
        }
        else {
            dispatch(getLeagues("0", "general", lang["latest_matches"], "WRL",langid));
        }

    }
    return (
        <div className={"header"}>
            <div className={"container"}>
                <div className={"row"}>
                    <div className={"col-md-4"}>
                     <a href={"/"}><img className={"logo_img"} src={"/images/system/logo5.png"} /></a>
                    </div>
                    <div className={"col-md-3"}>

                    </div>
                    <div className={"col-md-5"}>
                        <div className={"langs"}>
                            <a onClick={()=>{changelang("ar")}}><img src={"/images/system/iraq.png"} /></a>
                            <a onClick={()=>{changelang("en")}}> <img src={"/images/system/en.png"} /></a>
                            <a onClick={()=>{changelang("tr")}}> <img src={"/images/system/tr.png"} /></a>
                        </div>
                        {account.email ? (
                                <div className={"header_acc"}>
                                    <ul className={"user_top_nav"}>
                                        <li><a href={"/sportbets"}>{lang["bet_list"]}</a></li>
                                        <li><a href={"/user"}>{lang["my_account"]}</a></li>
                                        <li><a href={"/accountperiods"}>{lang["reports"]}</a></li>
                                        <li>
                                            <button onClick={() => Logout()} className={"logout_btn btn btn-danger"}>{lang["logout"]}</button>
                                        </li>

                                    </ul>
                                    <div className={"limits"}>{lang["limits"]} {allData.userbalance}</div>

                                </div>
                            ):(
                                <div className={"headerform"}>
                                    <input  onChange={t => setForm(t,"email")}  placeholder={lang["username"]} className={"form-control top_input"} />
                                    <input type={"password"} onChange={t => setForm(t,"password")} placeholder={lang["password"]} className={"form-control top_input"} />
                                    <button onClick={() => onSubmit()} className={"btn btn-danger"}>{lang["login"]}</button>

                                </div>
                            )
                        }

                    </div>
                </div>
            </div>
        </div>

    );
}
export  default Header;
