import React from 'react';
import Header from '../components/site/Header';
import Topnav from '../components/site/Topnav';
import Leftnav from "../components/user/Leftnav";
import Usercontainer from "../components/user/Usercontainer";
export default function Accountperiods (currentAppLocale) {
    const lang = currentAppLocale.messages;
    return (
        <div className="main">
            <Header lang={currentAppLocale} />
            <Topnav lang={currentAppLocale} />
            <div className="main">
                <div className={"row"}>
                    <div className={"col-md-2"}>
                        <Leftnav lang={currentAppLocale} />
                    </div>
                    <div className={"col-md-10"}>
                        <div className={"section_title"}>{lang['accountperiods']}</div>

                    </div>
                </div>
            </div>
        </div>
    );

}

