import antdArEG from 'antd/es/locale/ar_EG';
import arMsg from "../locales/ar_EG.json";

const arLang = {
    antd: antdArEG,
    locale: 'ar-EG',
    messages: {
        ...arMsg
    },
};
export default arLang;
