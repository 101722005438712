import React from 'react';
import Header from '../components/site/Header';
import Topnav from '../components/site/Topnav';
import Leftnav from "../components/user/Leftnav";
import {useDispatch, useSelector} from "react-redux";
import {changePass} from "../actions/general";
export default function Password (currentAppLocale) {
    const lang = currentAppLocale.messages;
    const dispatch = useDispatch();
    const allData = useSelector((state) => state.general);

    const [oldpass,setOldpass] = React.useState("");
    const [newpass,setNewpass] = React.useState("");
    const [warning,setWarning] = React.useState(false);
    const [newpass2,setNewpass2] = React.useState("");
    const changePassword = () => {
        if(newpass===newpass2) {
            let userdata = {
                "email":allData.userdata.email,
                "oldpass":oldpass,
                "newpass":newpass
            }

           dispatch(changePass(userdata));
            setWarning(true);
            setTimeout(function(){
                setWarning(false);
                setNewpass("");
                setNewpass2("");
                setOldpass("");
            },3000);

        }
        else {
            alert("Yeni şifreleriniz uyuşmuyor!");
        }
    }
    return (
        <div className="main">
            <Header lang={currentAppLocale} />
            <Topnav lang={currentAppLocale} />
            <div className="main">
                <div className={"row"}>
                    <div className={"col-md-2"}>
                        <Leftnav lang={currentAppLocale} />
                    </div>
                    <div className={"col-md-10"}>
                        <div className={"section_title"}>{lang["change_pass"]}</div>
                        <div className={"passbox "}>
                            <div className={"row"}>
                                <div className={"col-md-4"}>
                                    <img src={"/images/system/password.png"} className={"w100"} />

                                </div>
                                <div className={"col-md-8"}>
                                    {warning && ( <div className={"warn"}>{allData.changepass.success}! {allData.changepass.message}</div>)}

                                    <div className={"block"}>

                                    <input value={oldpass || ''} onChange={(e)=>setOldpass(e.target.value)} placeholder={lang["old_pass"]} type={"password"} className={"form-control text_input"} />
                                    </div>
                                    <div className={"block"}>

                                    <input value={newpass || ''} onChange={(e)=>setNewpass(e.target.value)} placeholder={lang["new_pass"]} type={"password"} className={"form-control text_input"} />
                                    </div>
                                    <div className={"block"}>

                                    <input value={newpass2 || ''} onChange={(e)=>setNewpass2(e.target.value)} placeholder={lang["new_pass_again"]} type={"password"} className={"form-control text_input"} />
                                    </div>
                                    <div className={"block"}>
                                    <button onClick={()=>{changePassword()}} className={"btn reg_button"}>{lang["change_pass"]}</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );

}

