import React, {useEffect} from 'react';
import Header from '../components/site/Header';
import Topnav from '../components/site/Topnav';
import Leftnav from "../components/user/Leftnav";
import axios from "axios";
import {WEB_CONFIG} from "../config/Static";
import _ from "lodash";
import { useParams } from 'react-router-dom';
import {useSelector} from "react-redux";

export default function Coupondetail (currentAppLocale) {
    const detail = useParams();
    const lang = currentAppLocale.messages;
    const [maincoupon,setMaincoupon] = React.useState([])
    const [cp,setCp] = React.useState([])
    const [resstat,setRestat] = React.useState(null)
    const [warn,setWarn] = React.useState({
        status:"",
        message:""
    })
    const Coupons = async (detail) => {
     await   axios.post(
            WEB_CONFIG.server+'/api/sport/coupondetails',
         detail,
            { headers: { 'Content-Type': 'application/json' }}
        )
            .then(response =>{
                controlResult(response.data[1]);
                setCp(response.data[1]);
                setMaincoupon(response.data[0]);
            })
            .catch(error => {
                console.log(error.data)
            });

    }
    const Coupons2 = async (detail) => {
        await   axios.post(
            WEB_CONFIG.server+'/api/sport/coupondetails',
            detail,
            { headers: { 'Content-Type': 'application/json' }}
        )
            .then(response =>{

                setCp(response.data[1]);
                setMaincoupon(response.data[0]);
            })
            .catch(error => {
                console.log(error.data)
            });

    }
   const controlResult = async (bets) => {
          let bt = bets.filter(b => b.status==='PENDING');

        _.forEach(bt, async function(bet){
            console.log("bet");    console.log(bet);
            bet.ext_id=bet.ext_id.split("match:")[1];
            let res = [];
            res.push(bet);
         await axios.get(`${WEB_CONFIG.server}/getmatches/ext_`+bet.ext_id)
             .then(result => {
                 res.push(result.data);
                 console.log("result.data");  console.log(result.data);


                  axios.post(
                     WEB_CONFIG.server+'/api/sport/matchresult',
                      res,
                     { headers: { 'Content-Type': 'application/json' }}
                 ).then(response => {

                      Coupons2(detail);
                  })
             }).catch(error => {
                 console.log(error);
             });


         });



   }
    useEffect(() => {
        Coupons(detail);
    },[])
    const openCoupon = (id) => {
        window.location.href = "/coupon_detail/"+id;
    }
    const cekimTalebi = (coupon) => {

        axios.post(
            WEB_CONFIG.server + '/api/sport/withdrawrequest',
            coupon,
            {headers: {'Content-Type': 'application/json'}}
        )
            .then(response => {

                setWarn(response.data);
                setTimeout(function(){
                    setWarn({status:"",message: ""});
                },3000)
            })
            .catch(error => {
                console.log(error.data)
            });
    }
    return (
        <div className="main">
            <Header lang={currentAppLocale} />
            <Topnav lang={currentAppLocale} />

                <div className={"row"}>
                    {warn.status && (
                        <div className={"warning"}>
                            <h3>{warn.status}</h3>
                            <p>{warn.message}</p>
                        </div>
                    )}

                    <div className={"col-md-2"}>
                        <Leftnav lang={currentAppLocale} />
                    </div>
                    <div className={"col-md-10"}>
                        <div className={"section_title "}>{lang["coupon_details"]}</div>
                        <div className={"section_title bet_filter2"}>

                            <div className={"cp_back"}>
                                <div className={"row"}>
                                    <div className={"col-md-2"}>
                                      <a href={"/sportbets"}><img alt={"back"} src={"/images/system/back.png"} /></a>
                                    </div>
                                    <div className={"col-md-10 text_right"}>
                                        <button onClick={() => window.print()}  className={"print_btn"}>{lang["print"]}</button>
                                        {maincoupon.winstatus==="WON" && (
                                            <button onClick={() => cekimTalebi(maincoupon)} className={"print_btn filter_clear"}>{lang["withdraw_request"]}</button>
                                        )}

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className={"bet_tables"}>
                            <div className={"cp_dt"}>
                                <div className={"row"}>
                                    <div className={"col-md-6"}>
                                        <div className={"row cp_row"}>
                                            <div className={"col-md-6"}>{lang["ticket_no"]} :</div>
                                            <div className={"col-md-6"}>{maincoupon.coupon_id}</div>
                                        </div>
                                        <div className={"row cp_row"}>
                                            <div className={"col-md-6"}>{lang["amount"]} :</div>
                                            <div className={"col-md-6"}>{maincoupon.price}</div>
                                        </div>
                                        <div className={"row cp_row"}>
                                            <div className={"col-md-6"}>{lang["max_gain"]} :</div>
                                            <div className={"col-md-6"}>{maincoupon.total}</div>
                                        </div>
                                    </div>
                                    <div className={"col-md-6"}>
                                        <div className={"row cp_row"}>
                                            <div className={"col-md-6"}> {lang["total_odds"]}:</div>
                                            <div className={"col-md-6"}>{maincoupon.total_amount}</div>
                                        </div>
                                        <div className={"row cp_row"}>
                                            <div className={"col-md-6"}>{lang["bet_status"]}:</div>
                                            <div className={"col-md-6"}>{lang[maincoupon.status]}</div>
                                        </div>
                                        <div className={"row cp_row"}>
                                            <div className={"col-md-6"}>{lang["player"]}:</div>
                                            <div className={"col-md-6"}>{maincoupon.user_id}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <table className={"bet_table"}>
                                <thead>
                                <tr>{lang["code"]}</tr>
                                <tr>{lang["date"]}/{lang["time"]}</tr>
                                <tr>{lang["teams"]}</tr>
                                <tr>{lang["guess"]}</tr>
                                <tr>{lang["odds"]}</tr>
                                <tr>{lang["match_time"]}</tr>
                                <tr>{lang["score"]}</tr>
                                <tr>{lang["bet_status"]}</tr>
                                <tr>{lang["result"]}</tr>
                                </thead>
                                <tbody>


                                {cp &&
                                cp.map((coupon,index) => {
                                    let bg = 'whitebg';
                                    if(coupon.oddresult==="WON") { bg="greenbg_inner"; } else if(coupon.oddresult==="LOST") { bg="redbg_inner"; } else { bg="whitebg"; }
                                    return (
                                        <tr className={bg} key={index}>
                                            <td>{coupon.id}</td>
                                            <td>{coupon.date}</td>
                                            <td>{coupon.first} - {coupon.second}</td>
                                            <td>{coupon.odd_name?coupon.odd_name:coupon.selected}</td>
                                            <td>{coupon.odd}</td>
                                            <td>{coupon.elapsed_time}</td>
                                            <td className={"tdx"} >{coupon.matchresult}</td>
                                            <td className={"tdx"}>{lang[coupon.status]}</td>
                                            {coupon.status==='PENDING' || coupon.status==='NOTSTARTED' ? (
                                                <td className={"tdx"}>{lang[coupon.oddresult+"_LIVE"]}</td>
                                            ):(
                                                <td className={"tdx"}>{lang[coupon.oddresult]}</td>
                                            )}

                                        </tr>
                                    );
                                })
                                }



                                </tbody>
                            </table>


                        </div>
                    </div>
                </div>
            <div className={"p_coupon"}>
               <div className={"grey_box"}>
                   <div className={"row"}>
                       <div className={"col-md-6 left"}>{lang["player"]} :</div>
                       <div className={"col-md-6 align_right"}>{maincoupon.user_id}</div>
                   </div>
                   <div className={"row"}>
                       <div className={"col-md-6 left"}> {lang["ticket_no"]} :</div>
                       <div className={"col-md-6 align_right"}>{maincoupon.coupon_id}</div>
                   </div>
                   <div className={"row"}>
                       <div className={"col-md-6 left"}> {lang["date"]} :</div>
                       <div className={"col-md-6 align_right"}>{maincoupon.date}</div>
                   </div>
               </div>


                {cp &&
                cp.map((coupon,index) => {
                    return (
                        <div className={"white_box"}>
                            <div className={"row"}>
                                <div className={"col-md-6 left"}>{lang["football"]} :</div>
                                <div className={"col-md-6 align_right"}>{coupon.date}
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col-md-6 left"}>{coupon.first} - {coupon.second}</div>
                                <div className={"col-md-6 align_right"}> </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col-md-6 left"}> {lang["guess"]}: {coupon.selected}</div>
                                <div className={"col-md-6 align_right"}>{coupon.odd}</div>
                            </div>
                        </div>
                    )
                })  }
                <div className={"grey_box"}>
                    <div className={"row"}>
                        <div className={"col-md-6 left"}> {lang["amount"]} :	</div>
                        <div className={"col-md-6 align_right"}>{maincoupon.price}
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-md-6 left"}> {lang["odds"]} :</div>
                        <div className={"col-md-6 align_right"}>{maincoupon.total_amount}</div>


                    </div>
                    <div className={"row"}>
                        <div className={"bigp"}>DNR {maincoupon.total}</div>
                    </div>

                </div>
            </div>
        </div>
    );

}
