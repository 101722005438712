import React, { Component } from 'react';
import {BrowserRouter, Route} from 'react-router-dom';
import Index from './pages';
export default class App extends Component {
  state = {
    theme: 'light',
  };

  render() {
      let siteurl =window.location.origin;
      if(siteurl==="https://.com") {
          return (
              <>
                  <BrowserRouter>
                      <div className={"webuyari"}><h3>Dikkat Bu siteyi geçerli olmayan yollarla kullanıyorsunuz! IP Adresiniz Alınmıştır.</h3></div>
                  </BrowserRouter>
              </>
          );
      }
      else {
          return (
              <>
                  <BrowserRouter>
                      <Index />
                  </BrowserRouter>
              </>
          );
      }

  }
}
