import React from 'react';



export default function Leftnav (props) {
    const lang = props.lang.messages;
    return (
        <div className={"leftnav"}>

            <ul className={"user_left"}>
                <li><a href={"/user"}>{lang["user_info"]}</a> </li>
                <li><a href={"/password"}>{lang["change_pass"]}</a> </li>
                <li><a href={"/sportbets"}>{lang["sportbets"]}</a> </li>
                <li><a href={"/account"}>{lang["account"]}</a> </li>
                <li><a href={"/accountperiods"}>{lang["accountperiods"]}</a> </li>
                <li><a href={"/members"}>{lang["members"]}</a> </li>

            </ul>
        </div>

    );

}

