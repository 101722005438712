import { combineReducers } from "redux";
import postReducer from "./post";
import orderReducer from "./orders";
import coinReducer from "./coin";
import newsReducer from "./news";
import Theme from "./Theme"
import userwalletsReducer from "./userwallets";
import generalReducer from "./general";
const rootReducer = combineReducers({general: generalReducer, theme: Theme, posts: postReducer,orders: orderReducer,coins: coinReducer, news: newsReducer,userwallets: userwalletsReducer });

export default rootReducer;
