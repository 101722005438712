import React, {useEffect, useState} from "react";
import Leftnav from "./Leftnav";
import Buletin from "./Buletin";
import Slip from "./Slip";
import {Leaguesjson} from "../../config/Api";
import Livebulletin from "./Livebulletin";
const Livematches = (props) => {
    const [leagues, setLeagues] = useState([]);
    const getLeagues = async () => {
        const ligler = Leaguesjson.Result[0].Items;
        setLeagues(ligler)

    }
    useEffect(() => {
        getLeagues();
    }, [leagues]);

    return (
        <div className={"matches"}>
            <div className={"row no_margin"}>

                <div className={"col-md-10 pr5"}>
                    <Livebulletin lang={props.lang.messages} />
                </div>
                <div className={"pl0 col-md-2"}>
                    <Slip lang={props.lang.messages} />
                </div>
            </div>
        </div>
    );
}
export  default Livematches;
