import React, {useEffect, useState} from "react";
import axios from "axios";
import {WEB_CONFIG} from "../../config/Static";

const Ads = (props) => {
    const [ad,setAd] = useState([])
    const getAds = async () => {
        axios.post(
            WEB_CONFIG.server + '/api/sport/getads',

            {headers: {'Content-Type': 'application/json'}}
        )
            .then(response => {
                console.log("adsresponse.data"); console.log(response.data);
                setAd(response.data);
            })
            .catch(error => {
                console.log(error.data)
            });
    }
    useEffect(() => {
      getAds();
    }, []);
    return (
        <div className={"site_ds"}>

            {ad.length>0 && ad.map((item,index) => {
                return (
                  <div className={"single_ad"}>
                      <a target={"_blank"} href={item.link}>
                      <img src={"https://upload.trwebserver.com/upload/images/images/"+item.image} className={"ad_img"} />
                      </a>
                  </div>
                );
            })}
        </div>

    );
}
export  default Ads;
