import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getLeagues} from "../actions/general";
import teams_ar from "../lang/locales/team_ar.json";
export default function Dil (currentAppLocale) {
    const dispatch = useDispatch();
    const allData = useSelector((state) => state.general);
    useEffect(() => {

        dispatch(getLeagues("0", "general", "Latest Matches", "WRL", "en"));


    }, []);
    const matches =allData.currentleague?allData.currentleague.Result:[];

    return (
        <div className={"dil"}>
            {matches.Items.length>0 &&
            matches.Items[0].Events.map((item, index) => {

                return (

                    <div className={"d"}>
                        <div className={"col-md-12"}>{teams_ar[0].teams[item.Competitors[0].Name]?"":item.Competitors[0].Name}</div>
                        <div className={"col-md-12"}>{teams_ar[0].teams[item.Competitors[1].Name]?"":item.Competitors[1].Name}</div>

                    </div>

                );
            })
            }
        </div>
    );

}
