import React, {useEffect, useState} from "react";
import Leftnav from "./Leftnav";
import Buletin from "./Buletin";
import Slip from "./Slip";
import {Leaguesjson} from "../../config/Api";
import {searchmatch,getLeftleagues} from "../../actions/general";
import {useDispatch} from "react-redux";
import {useCookies} from "react-cookie";
const Homematches = (props) => {
    const [cookies,setCookie] = useCookies();
    const dispatch = useDispatch();
    const [leagues, setLeagues] = useState([]);
    const getLeagues = async () => {

        dispatch(getLeftleagues(cookies.lang));

    }
    useEffect(() => {
        getLeagues();
    }, [leagues]);

    return (
        <div className={"matches"}>
            <div className={"row"}>
                <div className={"col-md-2"}>
                    <Leftnav lang={props.lang.messages} leagues={leagues}/>
                </div>
                <div className={"col-md-8 pr5"}>
                    <Buletin lang={props.lang.messages} />
                </div>
                <div className={"pl0 col-md-2"}>
                    <Slip lang={props.lang.messages}/>
                </div>
            </div>
        </div>
    );
}
export  default Homematches;
