import React, {useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {addOdd, deleteOdd} from "../../actions/general";
import _ from "lodash";

const Livesportlist = (props) => {
    const dispatch = useDispatch();
    const allData = useSelector((state) => state.general);
    const [open,setOpen] = useState(0)
    const matches =allData.currentleague?allData.currentleague.Result:[];
    const replacer = (item) => {
        return item.replace("Over",props.lang["over"]).replace("Draw",props.lang["draw"])
            .replace("Under",props.lang["under"]).replace("or draw",props.lang["or_draw"]).replace("Draw or",props.lang["draw_or"])
            .replace("Yes",props.lang["yes"]).replace("Goals",props.lang["goals"])
            .replace("No",props.lang["no"]).replace("Other",props.lang["other"]).replace("Both teams",props.lang["both_teams"])
            .replace("Odd",props.lang["odd"]).replace("Even",props.lang["even"]).replace("or",props.lang["or"])
    }
    const addCoupon = (order,index,odd,oynamadurumu) => {
        if(order) {
        let orderName=order.Name;
        if(order.Name===props.lang['1x2']) { orderName=props.lang["match_score"]; }
        let odd_detail = {
            "id":odd.Id,
            "odd":order.Items[index].Price,
            "odd_id":order.Items[index].Name.replace("Gol","Goal").replace("gol","goal").replace("Çift","Even").replace("Tek","Odd").replace("altı",props.lang['alti']).replace("üstü",props.lang['ustu']).replace(odd.Competitors[0].Name,1).replace(odd.Competitors[1].Name,2).replace(odd.Competitors[0].Name,1).replace(odd.Competitors[1].Name,2).replace("Beraberlik",0).replace("beraberlik",0).replace("Beraberlik",0),
            "ext_id":odd.ExtId,
            "event_date":odd.EventDate,
            "spov":order.Items[index].SPOV,
            "market_id":order.MarketTypeId,
            "odd_option":order.SpecialOddsValue,
            "option_id":order.Items[index].Id,
            "first":odd.Competitors[0].Name,
            "second":odd.Competitors[1].Name,
            "select_side":index,
            "selected":orderName+" "+order.Items[index].Name
        }

        if(oynamadurumu==='active') {
            dispatch(deleteOdd(odd.Id,odd_detail));

        } else {
            dispatch(addOdd(odd.Id,odd_detail));
        }
        }
        else {
           // console.log("order 13333");  console.log(order);
        }
    }

    const renderItemdetail = (item,odd) => {

        if(item.ColumnCount===3) {
            return (
                <div className={"detailitems"}>
                    <div className={"detail_title"}>{props.lang[item.Name.replace("/","_")]?props.lang[item.Name.replace("/","_")]:item.Name.replace("/","_")}  {item.SpecialOddsName} </div>

                    <div className={"detail_match row"} >
                        {item.Items.map((option,index) => {
                                const oynamakontrol= allData.odddata.filter(odds => odds.id ===odd.Id );
                                let first_oynanmis=" passive ";
                                let draw_oynanmis=" passive ";
                                let second_oynanmis=" passive ";
                                if(oynamakontrol.length>0) {
                                    if(Number(option.Id)===Number(oynamakontrol[0].option_id)) first_oynanmis = 'active'
                                    if(Number(option.Id)===Number(oynamakontrol[0].option_id)) draw_oynanmis = 'active'
                                    if(Number(option.Id)===Number(oynamakontrol[0].option_id)) second_oynanmis = 'active'
                                }
                                let oynamadurumu =" ";
                                let title = "left_align";   let odd_option = "right_align"; let cent=" ";
                                if(index===0) { oynamadurumu=first_oynanmis; title = "left_align"; odd_option = "right_align"; }
                                else if(index===1) { oynamadurumu=draw_oynanmis; cent=" cent "; title = "centered2"; odd_option = "centered2"; }
                                else { oynamadurumu = second_oynanmis; title = "right_align"; odd_option = "left_align"; }
                                return (
                                    <div onClick={() => option.IsActive===true?addCoupon(item,index,odd,oynamadurumu):''} className={"col-md-4 m_white "+cent+oynamadurumu}>
                                        <div className={title}>{replacer(option.Name).replace(odd.Competitors[0].Name,1).replace(odd.Competitors[1].Name,2).replace(odd.Competitors[0].Name,1).replace(odd.Competitors[1].Name,2)}</div>
                                        {option.IsActive===true?(
                                            <div   className={odd_option}>{Number(option.Price).toFixed(2)}</div>
                                        ):(
                                            <div className={"closed_bet "+odd_option}>
                                                <img src={"/images/system/lock.png"} />
                                            </div>
                                        )}
                                    </div>
                                )

                            }
                        )}
                    </div>
                </div>
            )
        }
        else {

            return (
                <div className={"detailitems"}>
                    <div className={"detail_title"}>{props.lang[item.Name.replace("/","_")]?props.lang[item.Name.replace("/","_")]:item.Name.replace("/","_")}  {item.SpecialOddsName}</div>

                    <div className={"detail_match row "} >
                        {item.Items.map((option,index) => {
                            const oynamakontrol= allData.odddata.filter(odds => odds.id ===odd.Id );
                            let first_oynanmis=" passive ";
                            let draw_oynanmis=" passive ";
                            let second_oynanmis=" passive ";
                            if(oynamakontrol.length>0) {
                                if(Number(option.Id)===Number(oynamakontrol[0].option_id)) first_oynanmis = 'active'
                                if(Number(option.Id)===Number(oynamakontrol[0].option_id)) second_oynanmis = 'active'
                            }
                            let oynamadurumu =" ";
                            let title = "left_align";   let odd_option = "right_align"; let cent="";
                            if(index===0) {oynamadurumu=first_oynanmis; title = "left_align"; odd_option = "right_align"; }
                            else { oynamadurumu = second_oynanmis; title = "right_align"; odd_option = "left_align"; }
                            return (
                                <div onClick={() => option.IsActive===true?addCoupon(item,index,odd,oynamadurumu):''}  className={"col-md-6 m_white "+oynamadurumu}>
                                <div className={title}> {replacer(option.Name)}</div>
                                    {option.IsActive===true?(
                                        <div  className={odd_option}>{Number(option.Price).toFixed(2)}</div>
                                    ):(
                                        <div className={"closed_bet "+odd_option}><img src={"/images/system/lock.png"} /></div>
                                    )}
                                </div>
                            )
                        } )}
                    </div>
                </div>
            )
        }
    }
    const renderItem2 =  (odd,index) => {
        let odditems = odd.Items?odd.Items:[];
        const oynamakontrol= allData.odddata.filter(odds => odds.id ===odd.Id );
          let anabahisler = odd.Items[0] !==undefined ? odd.Items[0].Items:[];
           if(odd.Items.filter(ana => ana.Name==="1x2")[0]!==undefined){
               anabahisler =odd.Items.filter(ana => ana.Name==="1x2")[0].Items;
           }
           else {
               return;
           }
            let    anabahisler2 = odd.Items.filter(ana => ana.Name==="1x2")[0];

        let first_oynanmis="passive";
        let draw_oynanmis="passive";
        let second_oynanmis="passive";
        if(oynamakontrol.length>0) {
            if(Number(anabahisler[0].Id)===Number(oynamakontrol[0].option_id)) first_oynanmis = 'active'
            if(Number(anabahisler[1].Id)===Number(oynamakontrol[0].option_id)) draw_oynanmis = 'active'
            if(Number(anabahisler[2].Id)===Number(oynamakontrol[0].option_id)) second_oynanmis = 'active'
        }

        return (
            <div key={odd.Id} >
                <div className={"league_match row"} >
                    <div className={"col-md-1 m_blue blue_top"}>
                        {odd.LiveCurrentTime}
                    </div>
                    <div className={"col-md-1 m_blue"}>{odd.LiveScore}</div>
                    {anabahisler[0].IsActive===true?(
                        <div onClick={() => addCoupon(anabahisler2,0,odd,first_oynanmis)}   className={"col-md-4 m_white "+first_oynanmis}>
                            {allData.teams[odd.Competitors[0].Name]?allData.teams[odd.Competitors[0].Name]:odd.Competitors[0].Name}


                            <span className={"right_align first_choice"}>{Number(anabahisler[0].Price).toFixed(2)}</span>
                        </div>

                    ):(
                        <div  className={"col-md-4 m_white "+first_oynanmis}>
                            {allData.teams[odd.Competitors[0].Name]?allData.teams[odd.Competitors[0].Name]:odd.Competitors[0].Name}
                            <span className={"right_align closed_bet"}><img src={"/images/system/lock.png"} /></span>
                        </div>
                    )}

                    {anabahisler[1].IsActive===true? (
                        <div onClick={() => addCoupon(anabahisler2,1,odd,draw_oynanmis)}  className={"col-md-1 m_white centered "+draw_oynanmis}>
                            <span >{Number(anabahisler[1].Price).toFixed(2)}</span>
                        </div>

                    ):(
                        <div  className={"col-md-1 m_white centered "+draw_oynanmis}>
                            <span className={"closed_bet"}><img src={"/images/system/lock.png"} /></span>
                        </div>
                    )}
                    {anabahisler[2]&&anabahisler[2].IsActive===true?(
                        <div onClick={() => addCoupon(anabahisler2,2,odd,second_oynanmis)}  className={"col-md-4 m_white "+second_oynanmis}>

                            <span  className={"left"}>{Number(anabahisler[2].Price).toFixed(2)}</span>
                            <span className={"right_align"}>
                                                          {allData.teams[odd.Competitors[1].Name]?allData.teams[odd.Competitors[1].Name]:odd.Competitors[1].Name}

                            </span>
                        </div>
                    ):(
                        <div  className={"col-md-4 m_white "+second_oynanmis}>
                            <span className={"left closed_bet"}><img src={"/images/system/lock.png"} /></span>
                            <span className={"right_align"}>
                               {allData.teams[odd.Competitors[1].Name]?allData.teams[odd.Competitors[1].Name]:odd.Competitors[1].Name}
                            </span>
                        </div>
                    )}


                    <div onClick={() => openItems(odd.Id)} className={"col-md-1 m_blue plus"}>+{(odditems.length*5)+1}</div>
                </div>
                {
                    open===odd.Id && (
                        <div className={"openitems"}>
                            {odditems.map(item => { return renderItemdetail(item,odd)})}
                        </div>
                    )

                }


            </div>
        );

    }
    const openItems = (Id) => {
        if(open===Id) { setOpen(0); } else { setOpen(Id) }
    }
    const RenderItem5 = (oddlist,index) => {
        let flag = oddlist.ISO?oddlist.ISO:'WRL';
        return (
            <>
                <div className={"row league"}>
                    <div className={"league_title"}>
                        <img src={"/images/flags/"+flag+".png"} />  <span> |  {allData.teams[oddlist.Name]?allData.teams[oddlist.Name]:oddlist.Name}  </span>
                    </div>
                </div>
                {
                    oddlist.Events.map((item,index) => { return renderItem2(item,index)})
                }
            </>
        );
    }
    return (
        <div className={"league_chart"}>
            <div className={"row league"}>
                <div className={"league_title"}>
                    <span> {allData.listtype.title}</span>
                </div>
            </div>

            {
                matches.Items.length>0 && matches.Items[0].Items?.map((odd,index) => (RenderItem5(odd,index) ))
            }

        </div>
    );
}

const mapStateToProps = ({ theme }) => {
    return theme;
};
export default connect(mapStateToProps, {  })(Livesportlist);
