import React, {useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {addOdd, deleteOdd} from "../../actions/general";
import _ from "lodash";
import axios from "axios";
import {WEB_CONFIG} from "../../config/Static";
import {useCookies} from "react-cookie";

const Sportlist = (props) => {
    const dispatch = useDispatch();

    const allData = useSelector((state) => state.general);
    const [open,setOpen] = useState(0)
    const [cookies,setCookie] = useCookies();
    const [anabahislers,setAnabahislers] = useState([])
    const [firstpart,setFirstpart] = useState([])
    const [fourthpart, setFourthpart] = useState([]);

    const [secondpart,setSecondpart] = useState([])
    const [thirdpart,setThirdpart] = useState([])
    const matches =allData.currentleague?allData.currentleague.Result:[];
    const openItems = async (Id) => {
        let lng = "en";
        if(cookies.lang==='tr') { lng = "tr";  }
        await   axios.get(
            WEB_CONFIG.server+"/getmatches/detail_"+lng+"_"+Id,
            { headers: { 'Content-Type': 'application/json' }}
        )
            .then(response =>{
                if(open===Id) {  setOpen(0); } else {  setOpen(Id) }

                setAnabahislers(response.data.Result.MarketGroups[0].Items.filter(f => f.MarketTypeId!=="11" && f.MarketTypeId!=="9" && f.MarketTypeId!=="8" && f.MarketTypeId!=="17711" && f.MarketTypeId!=="17712"  && f.MarketTypeId!=="220" && f.MarketTypeId!=="122" && f.MarketTypeId!=="112"  )  )
                setFirstpart(response.data.Result.MarketGroups[1].Items?response.data.Result.MarketGroups[1].Items.filter(f => f.MarketTypeId!=="1" && f.MarketTypeId!=="10"  && f.MarketTypeId!=="18" &&  f.MarketTypeId!=="166" && f.MarketTypeId!=="172" && f.MarketTypeId!=="163" && f.MarketTypeId!=="177"  && f.MarketTypeId!=="183" && f.MarketTypeId!=="136" && f.MarketTypeId!=="139"  ):[]);
                setSecondpart(response.data.Result.MarketGroups[2].Items?response.data.Result.MarketGroups[2].Items:[]);
                setThirdpart(response.data.Result.MarketGroups[3].Items?response.data.Result.MarketGroups[3].Items:[]);
                setFourthpart(response.data.Result.MarketGroups[4].Items?response.data.Result.MarketGroups[4].Items.filter(f => f.MarketTypeId!=="86" && f.MarketTypeId!=="551" && f.MarketTypeId!=="3253" && f.MarketTypeId!=="3254"):[]);
            })
            .catch(error => {
            //   console.log(error.data)
            });
    }
    const addCoupon = (order,index,odd,oynamadurumu) => {
        let orderName=order.Name;
        if(order.Name===props.lang['1x2']) { orderName=props.lang["match_score"]; }
        let odd_detail = {
            "id":odd.Id,
            "odd":Number(order.Items[index].Price).toFixed(2),
            "odd_id":order.Items[index].Name.replace("Gol","Goal").replace("gol","goal").replace("Çift","Even").replace("Tek","Odd").replace("altı",props.lang['alti']).replace("üstü",props.lang['ustu']).replace(odd.Competitors[0].Name,1).replace(odd.Competitors[1].Name,2).replace(odd.Competitors[0].Name,1).replace(odd.Competitors[1].Name,2).replace("Beraberlik",0).replace("beraberlik",0).replace("Beraberlik",0),
            "ext_id":odd.ExtId,
            "event_date":odd.EventDate,
            "spov":order.Items[index].SPOV,
            "market_id":order.MarketTypeId,
            "odd_name":replacer2(orderName+" "+order.Items[index].Name).replace(odd.Competitors[0].Name,"1").replace(odd.Competitors[0].Name,"1").replace(odd.Competitors[1].Name,"2").replace(odd.Competitors[1].Name,"2"),
            "odd_option":order.SpecialOddsValue,
            "option_id":order.Items[index].Id,
            "first":odd.Competitors[0].Name,
            "second":odd.Competitors[1].Name,
            "select_side":index,
            "selected":orderName+" "+order.Items[index].Name
        }

        if(oynamadurumu==='active') {
            dispatch(deleteOdd(odd.Id,odd_detail));
        } else {
            dispatch(addOdd(odd.Id,odd_detail));
        }
    }
    const replacer = (item) => {
        return item.replace("Over",props.lang["over"]).replace("Draw",props.lang["draw"])
            .replace("Under",props.lang["under"]).replace("or draw",props.lang["or_draw"]).replace("Draw or",props.lang["draw_or"])
            .replace("Yes",props.lang["yes"]).replace("Goals",props.lang["goals"])
            .replace("No",props.lang["no"]).replace("Other",props.lang["other"]).replace("Both teams",props.lang["both_teams"])
            .replace("Odd",props.lang["odd"]).replace("Even",props.lang["even"]).replace("or",props.lang["or"])
    }
    const replacer2 = (item) => {
        return item.replace("None",props.lang["none"]).replace("Half first Goal First",props.lang["half_first_goal_first"]).replace("Half first Goal Second",props.lang["half_first_goal_second"]).replace("2nd",props.lang["second"]).replace("half",props.lang["half"]).replace("Equal",props.lang["equal"]).replace("highest",props.lang["highest"]).replace("Double chance",props.lang["double_chance"]).replace("total",props.lang["total"]).replace("over",props.lang["over"]).replace("correct",props.lang["correct"]).replace("score",props.lang["score"]).replace("Halftime",props.lang["Halftime"]).replace("fulltime",props.lang["fulltime"]).replace("Over",props.lang["over"]).replace("Draw",props.lang["draw"])
            .replace("Under",props.lang["under"]).replace("or draw",props.lang["or_draw"]).replace("Draw or",props.lang["draw_or"])
            .replace("Yes",props.lang["yes"]).replace("Goals",props.lang["goals"])
            .replace("No",props.lang["no"]).replace("Other",props.lang["other"]).replace("Both teams",props.lang["both_teams"])
            .replace("Odd",props.lang["odd"]).replace("Even",props.lang["even"]).replace("or",props.lang["or"]).replace("Half",props.lang["half"]).replace("first",props.lang["first"]).replace("Goal",props.lang["goal"]).replace("under",props.lang["under"]).replace("Total",props.lang["total"]).replace("double",props.lang["double"]).replace("chance",props.lang["chance"]).replace("1st",props.lang["first"])
    }
    const renderItemdetail = (item,odd,tip) => {
        if(item.ColumnCount===3) {
            let brow ="";
            if(item.Items.length>4) {  brow = " block_row ";  } else {  brow = " ";  }
            return (
                <div className={"detailitems"}>
                    <div className={"detail_title"}>{props.lang[item.Name.replace("/","_")]?props.lang[item.Name.replace("/","_")]:item.Name.replace("/","_")}  {item.SpecialOddsName} </div>
                    <div className={"detail_match row "+brow} >
                        {item.Items.map((option,index) => {

                            const oynamakontrol= allData.odddata.filter(odds => odds.id ===odd.Id );
                            let first_oynanmis=" passive ";
                            let draw_oynanmis=" passive ";
                            let second_oynanmis=" passive ";
                            if(oynamakontrol.length>0) {
                                if(Number(option.Id)===Number(oynamakontrol[0].option_id)) first_oynanmis = 'active'
                                if(Number(option.Id)===Number(oynamakontrol[0].option_id)) draw_oynanmis = 'active'
                                if(Number(option.Id)===Number(oynamakontrol[0].option_id)) second_oynanmis = 'active'
                            }
                            let oynamadurumu =" ";
                            let title = "left_align";   let odd_option = "right_align"; let cent=" ";
                            if(index===0) { oynamadurumu=first_oynanmis; title = "left_align"; odd_option = "right_align"; }
                            else if(index===1) { oynamadurumu=draw_oynanmis; cent=" cent "; title = "centered2"; odd_option = "centered3"; }
                            else { oynamadurumu = second_oynanmis; title = "left_align"; odd_option = "right_align"; }
                            return (
                                <div onClick={() => addCoupon(item,index,odd,oynamadurumu)} className={"col-md-4 m_white "+cent+oynamadurumu}>
                                <div className={title}>{replacer(option.Name).replace(odd.Competitors[0].Name,1).replace(odd.Competitors[1].Name,2).replace(odd.Competitors[0].Name,1).replace(odd.Competitors[1].Name,2)}</div>
                                <div className={odd_option}>{Number(option.Price).toFixed(2)}</div>
                                </div>
                                )

                        } )}
                    </div>
                </div>
            )
        }
        else {
            let brow ="";
            if(item.Items.length>4) {  brow = " block_row ";  } else {  brow = " ";  }
            let sdt = item.Items.filter(f =>f.SPOV!=="3" && f.SPOV!=="2" && f.SPOV!=="1" && f.SPOV.includes(".75")===false && f.SPOV.includes(".25")===false)
            return (
                <div className={"detailitems"}>
                    <div className={"detail_title"}> {props.lang[item.Name.replace("/","_")]?props.lang[item.Name.replace("/","_")]:item.Name.replace("/","_")} {item.SpecialOddsName}   </div>
                    <div className={"detail_match row "+brow} >
                        {

                            sdt.map((option,index) => {


                           // console.log("822 sondat"); console.log(option);
                            const oynamakontrol= allData.odddata.filter(odds => odds.id ===odd.Id );
                            let first_oynanmis=" passive ";
                            let draw_oynanmis=" passive ";
                            let second_oynanmis=" passive ";
                            if(oynamakontrol.length>0) {
                                if(Number(option.Id)===Number(oynamakontrol[0].option_id)) first_oynanmis = 'active'
                                if(Number(option.Id)===Number(oynamakontrol[0].option_id)) second_oynanmis = 'active'
                            }
                            let oynamadurumu =" ";
                            let title = "left_align";   let odd_option = "right_align"; let cent="";
                            if(index===0) {oynamadurumu=first_oynanmis; title = "left_align"; odd_option = "right_align"; }
                            else { oynamadurumu = second_oynanmis; title = "left_align"; odd_option = "right_align"; }
                            return (
                                <div onClick={() => addCoupon(item,index,odd,oynamadurumu)} className={"col-md-6 m_white "+oynamadurumu}>
                                    <div className={title}>{option.Name.split(" ")[1]} {replacer(option.Name.split(" ")[0])}: </div>
                                    <div className={odd_option}>{Number(option.Price).toFixed(2)}</div>
                                </div>
                            )

                        } )}
                    </div>
                </div>
            )
        }

    }
    const renderItem2 = (odd,index) => {

        const updatedDate = new Date(odd.EventDate);
        updatedDate.setHours(updatedDate.getHours() + 3);
        const formattedDate = updatedDate.toISOString();
        let edate = (formattedDate).split("T");
        let odditems = odd.Items;

        const oynamakontrol= allData.odddata.filter(odds => odds.id ===odd.Id );


        let anabahisler = odd.Items[0] !==undefined ? odd.Items[0].Items:[];
        if(odd.Items.filter(ana => ana.Name==="1x2")[0]!==undefined){
            anabahisler =odd.Items.filter(ana => ana.Name==="1x2")[0].Items;
        }
        else {
            return;
        }
        let    anabahisler2 = odd.Items.filter(ana => ana.Name==="1x2")[0];


        let first_oynanmis="passive";
        let draw_oynanmis="passive";
        let second_oynanmis="passive";
        if(oynamakontrol.length>0) {
            if(Number(anabahisler[0].Id)===Number(oynamakontrol[0].option_id)) first_oynanmis = 'active'
            if(Number(anabahisler[1].Id)===Number(oynamakontrol[0].option_id)) draw_oynanmis = 'active'
            if(Number(anabahisler[2].Id)===Number(oynamakontrol[0].option_id)) second_oynanmis = 'active'
        }
        return (
            <div key={odd.Id} >
            <div className={"league_match row"} >
                <div className={"col-md-1 m_blue"}>
                    {edate[0].split("2023-")[1]} </div>
                <div className={"col-md-1 m_blue"}>{edate[1].split(":00.000Z")[0]}</div>
                <div onClick={() => addCoupon(anabahisler2,0,odd,first_oynanmis)} className={"col-md-4 m_white "+first_oynanmis}>{allData.teams[odd.Competitors[0].Name]?allData.teams[odd.Competitors[0].Name]:odd.Competitors[0].Name}  <span className={"right_align"}>{Number(anabahisler[0].Price).toFixed(2)}</span></div>
                <div onClick={() => addCoupon(anabahisler2,1,odd,draw_oynanmis)} className={"col-md-1 m_white centered "+draw_oynanmis}>{Number(anabahisler[1].Price).toFixed(2)}</div>
                <div onClick={() => addCoupon(anabahisler2,2,odd,second_oynanmis)} className={"col-md-4 m_white "+second_oynanmis}><span className={"left"}>{anabahisler[2]?Number(anabahisler[2].Price).toFixed(2):<span className={"closed_bet"}><img src={"/images/system/lock.png"} /></span>}</span><span className={"right_align"}>{allData.teams[odd.Competitors[1].Name]?allData.teams[odd.Competitors[1].Name]:odd.Competitors[1].Name}</span></div>
                <div onClick={() => openItems(odd.Id)} className={"col-md-1 m_blue plus"}>+{(odditems.length*50)+231+odditems.length}</div>
            </div>
                {open===odd.Id && (
                    <div className={"openitems"}>
                        {anabahislers?.map(item => { return renderItemdetail(item,odd,"Ana")})}
                        {firstpart?.map(item => { return renderItemdetail(item,odd, "first")})}
                        {secondpart?.map(item => { return renderItemdetail(item,odd, "second")})}
                        {fourthpart?.map(item => { return renderItemdetail(item,odd,"fourth")})}
                       

                    </div>
                )}

            </div>
        );
    }
    const renderItem5 = (oddlist,index) => {
        let flag = oddlist[0].ISO?oddlist[0].ISO:'WRL';
        return (
            <>
                <div className={"row league"}>
                    <div className={"league_title"}>
                        <img src={"/images/flags/"+flag+".png"} />  <span> | {allData.teams[oddlist[0].ChampName]?allData.teams[oddlist[0].ChampName]:oddlist[0].ChampName}</span>
                    </div>
                </div>
                {oddlist.map((item,index) => { return renderItem2(item,index)})}
            </>
        );
    }
    const renderItem3 = (oddlist,index) => {
        let chmp = _.groupBy(oddlist, "ChampName");
        let finaldata = []
        _.forEach(chmp,function (odd){
            finaldata.push(odd);
        })
        return (
            <div key={index}>
            {finaldata.map((item,index) => { return renderItem5(item,index)})}
            </div>
        );
    }
    const RenderItem4 = (odds) => {
        let grp = _.groupBy(odds, "EventDate");
        let son = [];
        _.forEach(grp,function (g){
            son.push(g);
        });
    return(
            <div key={1}>
                {son.map((item,index) => { return renderItem3(item,index)})}
            </div>
    )
    }
    if(allData.listtype.type === 'general') {
    return (
        <div className={"league_chart"}>
     <div className={"row league"}>
         <div className={"league_title"}>
             <img src={"/images/flags/"+allData.listtype.image+".png"} />  <span> | {allData.listtype.title}</span>
         </div>
     </div>
            {
                matches.Items.length>0 ? (
                    <RenderItem4 {...matches.Items[0].Events} />
                ):(<>Loading</>)
            }

        </div>
    );

    }
    else {
        return (
            <div className={"league_chart"}>
                <div className={"row league"}>
                    <div className={"league_title"}>
                        <img src={"/images/flags/"+allData.listtype.image+".png"} />  <span> | {allData.listtype.title}</span>
                    </div>
                </div>

                {
                    matches.Items.length>0 ? matches.Items[0].Events.map((odd,index) => (renderItem2(odd,index) )):(<>Loading</>)
                }

            </div>
        );
    }
}

const mapStateToProps = ({ theme }) => {
    return theme;
};
export default connect(mapStateToProps, {  })(Sportlist);
